.Info {
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: .5rem;
  background-color: #ececec;
}

.Item {
  span {
    display: block;
    font-size: 1.3rem;
  }
  p {
    font-weight: 800;
    font-size: 1.4rem;
  }
}