.Table {
  width: 100%;
  text-align: left;
  position: relative;
  border-collapse: collapse;
}

.Th {
  top: 0;
  z-index: 10;
  position: sticky;
  background: #F5F7F9;
  box-shadow: 0px 1px 0px rgba(207, 215, 223, 0.25);
  border-radius: 2.5px 2.5px 0px 0px;
  padding: 2rem 1rem;
  font-size: 1.3rem;
}


.Td {
  padding: 1.5rem 1rem;
  font-size: 1.3rem;
  line-height: 135%;
}

.TdImage {
  width: 8.5rem;
  img {
    display: block;
    border-radius: .5rem;
  }
}

.TdControls {
  width: 17rem;
  div {
    display: flex;
    align-items: center;
  }
}


.Tr {
  cursor: pointer;
  background: #fff;
  border-bottom: 1px solid #EBEFF3;
  &:hover {
    background: #EBEFF3;
  }
}

.TrCard {
  background: #ecf5ed;
}


.Checkbox {
  width: 5rem;
}

.Image {
  width: 6rem;
}