.Button {
  cursor: pointer;
  outline: none;
  border: none;
  height: 4rem;
  padding: 0 3rem;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 600;
  background: #1c1c1c;
  border-radius: 10rem;
}

.Green {
  background: #21ba45;
}

.Orange {
  background: orange;
}