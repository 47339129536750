@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;800&display=swap);
.Login_Wrapper__1WQYO{width:100%;height:100vh;display:flex;justify-content:center;align-items:center;background:#252525}.Login_Form__2Yhi_{width:50rem;padding:4rem;background:#fff;border-radius:.5rem}.Login_Form__title__1dcTj{margin-bottom:2rem}.Login_Form__title__1dcTj h2{font-size:2.4rem;margin-bottom:.25rem}.Login_Form__button__1bA26{margin-top:3rem}
.Group_Group__aNP0_{margin-bottom:1.5rem}.Group_Group__aNP0_:last-child{margin-bottom:0}.Group_GroupFull__2bv8o{width:100% !important}.Group_Label__2Sj6a{color:#333;display:block;margin-bottom:.5rem;font-size:1.3rem;font-weight:600}.Group_Error__3jH-_{display:block;color:red;font-size:1.2rem;margin-top:.25rem}
.Input_Input__81Hdq{width:100%;height:3.8rem;outline:none;border-radius:.25rem;border:1px solid #ccc;background:#fff;padding-left:1rem}.Input_Input__81Hdq::-webkit-input-placeholder{font-size:1.2rem}.Input_Input__81Hdq:-ms-input-placeholder{font-size:1.2rem}.Input_Input__81Hdq::placeholder{font-size:1.2rem}
.Button_Button__3va37{cursor:pointer;outline:none;border:none;height:4rem;padding:0 3rem;color:#fff;font-size:1.4rem;font-weight:600;background:#1c1c1c;border-radius:10rem}.Button_Green__1OKj_{background:#21ba45}.Button_Orange__2V7LZ{background:orange}
.Paginator_Pagination__1rAgd{position:absolute;right:1.5rem;display:flex;align-items:center}.Paginator_Info__1iQ_w{font-size:1.3rem;margin-right:1.5rem}.Paginator_Buttons__1veFX{display:flex;margin-left:1rem}.Paginator_Button__3YD5j{height:3.2rem;min-width:3.2rem;border-radius:4px;border:1px solid #cfd7df;color:#12344d;cursor:pointer;margin-right:.5rem;font-size:14px;vertical-align:middle;-webkit-font-smoothing:antialiased;transition:.1s ease-in;background-image:linear-gradient(to bottom, #fff, #f3f5f7);display:flex;align-items:center;justify-content:center}.Paginator_Button__3YD5j img{width:1.2rem}.Paginator_Prev__3tGct img{-webkit-transform:rotate(180deg);transform:rotate(180deg)}
.Page_Wrapper__3suJ1{width:100%;padding-left:60px}.Page_Title__2q_4t{color:#fff;font-size:2rem;font-weight:600;padding:2rem 2rem;background:#222629}.Page_Content__10cfU{padding:2rem 2rem}.Page_Controls__Lea1d{display:flex;align-items:center;justify-content:space-between;background-color:#ececec;height:56px;padding:0 15px;padding-right:16rem}.Page_ControlsEnd__3wEU-{justify-content:flex-end}
.Navbar_Navbar__1GLMI{position:fixed;left:0;top:0;display:block;width:60px;height:100%;padding-top:10px;background:#1b1c1d;z-index:100}.Navbar_Navbar__1GLMI a{color:#fff;display:flex;align-items:center;justify-content:center;font-weight:600;opacity:.9;width:70%;margin:0 auto;position:relative;border-radius:.5rem}.Navbar_Navbar__1GLMI a:after{content:"";display:block;padding-bottom:100%}.Navbar_Navbar__1GLMI a:before{background:rgba(255,255,255,.08)}.Navbar_Navbar__1GLMI a:hover{background:#444;color:#fff}.Navbar_Navbar__1GLMI a:hover span{opacity:1;pointer-events:none;transition:all .25s ease-in}.Navbar_Navbar__1GLMI a i{font-size:1.6rem;margin-right:0;position:relative}.Navbar_Navbar__1GLMI a span{display:block;opacity:0;padding:.5rem 1rem;right:-1.5rem;position:absolute;background:#1b1c1d;white-space:nowrap;font-size:1.3rem;border-radius:.5rem;pointer-events:none;-webkit-transform:translateX(100%);transform:translateX(100%);transition:all .25s ease-in}.Navbar_Navbar__1GLMI a span:after{content:"";position:absolute;left:-0.35rem;top:1rem;width:0;height:0;border-top:.5rem solid rgba(0,0,0,0);border-right:.5rem solid #1b1c1d;border-bottom:.5rem solid rgba(0,0,0,0)}.Navbar_Navbar__1GLMI button{position:absolute;bottom:2rem;left:1.5rem;font-size:1.3rem !important}.Navbar_Logo__3Z_lU{display:flex;align-items:center;height:5.9rem;background:#fff;margin-bottom:1.5rem}.Navbar_Logo__3Z_lU img{display:block;margin:0 auto;width:67%}
.Grid_Grid__1rNJL{margin-top:2rem;display:flex;justify-content:space-between;flex-wrap:wrap}.Grid_Grid__1rNJL>div{width:24%}
.Device_Device__3jyHX{background:#f7f7f7;padding:2rem;width:100% !important;display:flex;justify-content:space-between;flex-wrap:wrap;margin-bottom:2rem;border-radius:1.2rem}.Device_Device__3jyHX>div{width:24%}
.Textarea_Textarea__3ZzXn{width:100%;padding-top:1rem;height:7.5rem;outline:none;border-radius:.25rem;border:1px solid #ccc;background:#fff;padding-left:1rem}.Textarea_Textarea__3ZzXn::-webkit-input-placeholder{font-size:1.2rem}.Textarea_Textarea__3ZzXn:-ms-input-placeholder{font-size:1.2rem}.Textarea_Textarea__3ZzXn::placeholder{font-size:1.2rem}
.Create_Button__3Z3T7{cursor:pointer;outline:none;border:none;height:4rem;padding:0 3rem;background:#21ba45;color:#fff;font-size:1.4rem;font-weight:600;border-radius:.25rem;margin-right:2rem}.Create_Green__35Ceo{background:#86c232}.Create_Orange__33U3c{background:#222629}.Create_Group__2RQZ0{padding:2rem;border-radius:12px;background:#f7f7f7;border:1px solid #dedede}.Create_Group__2RQZ0 h3{padding-bottom:10px;border-bottom:1px solid #dedede}
.Table_Table__1PFCH{width:100%;text-align:left;position:relative;border-collapse:collapse}.Table_Th__2S-vT{top:0;z-index:10;position:-webkit-sticky;position:sticky;background:#f5f7f9;box-shadow:0px 1px 0px rgba(207,215,223,.25);border-radius:2.5px 2.5px 0px 0px;padding:2rem 1rem;font-size:1.3rem}.Table_Td__3kBjm{padding:1.5rem 1rem;font-size:1.3rem;line-height:135%}.Table_TdImage__1ZAv0{width:8.5rem}.Table_TdImage__1ZAv0 img{display:block;border-radius:.5rem}.Table_TdControls__kCTZ0{width:17rem}.Table_TdControls__kCTZ0 div{display:flex;align-items:center}.Table_Tr__1I0Fx{cursor:pointer;background:#fff;border-bottom:1px solid #ebeff3}.Table_Tr__1I0Fx:hover{background:#ebeff3}.Table_TrCard__3QKqu{background:#ecf5ed}.Table_Checkbox__eCaSF{width:5rem}.Table_Image__1jSdX{width:6rem}
.Search_Search__2SN5f{position:relative;height:32px;width:48rem}.Search_Search__2SN5f input{border:1px solid #cfd7df;border-radius:4px;padding:4px 8px;padding-left:3rem;color:#92a2b1;position:absolute;width:100%;height:100%}.Search_Search__2SN5f img{position:absolute;left:1rem;top:0;bottom:0;z-index:1;margin:auto}
.Modal_Wrapper__38Wh7{width:100%;height:100vh;display:flex;justify-content:center;align-items:center;position:fixed;top:0;left:0;right:0;bottom:0;z-index:1000;background:rgba(0,0,0,.8)}.Modal_Container__14T-Q{width:40rem;padding:2rem;background:#fff;border-radius:.5rem;position:relative;z-index:1001}.Modal_Form__uJLtB{width:100%}
.Current_Info__3mmik{padding:2rem;display:flex;justify-content:space-between;align-items:center;border-radius:.5rem;background-color:#ececec}.Current_Item__2J8aG span{display:block;font-size:1.3rem}.Current_Item__2J8aG p{font-weight:800;font-size:1.4rem}
html {
    font-size: 10px;
    font-family: 'Open Sans', sans-serif;
}

iframe {
    pointer-events: none;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Open Sans', sans-serif;
}

h3 {
    font-size: 1.5rem;
}

tr, td {
    font-size: 1.3rem!important;
}

.select__placeholder {
    font-size: 1.3rem!important;
}

.loading {
    position: fixed;
    bottom: 0;
    right: 0;
    width: calc(100% - 22rem);
    height: calc(100% - 12rem);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
}

.company__info div {
    font-size: 1.5rem;
}

.edit {
    top: -.1rem;
    left: .2rem;
}

.g__logout {
    width: 4.2rem;
    height: 4.2rem;
    display: flex;
    align-content: center;
    justify-content: center;
    position: absolute;
    left: 0!important;
    right: 0!important;
    margin: auto !important;
}

.g__logout i {
    margin-right: 0;
    position: relative;
    left: -.25rem;
}
.g__logout span {
    display: block;
    opacity: 0;
    padding: .5rem 1rem;
    right: -1.5rem;
    position: absolute;
    background: #1b1c1d;
    white-space: nowrap;
    font-size: 1.3rem;
    border-radius: .5rem;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    transition: all .25s ease-in;
}

.g__logout span:after {
    content: "";
    position: absolute;
    left: -.35rem;
    top: 1rem;
    width: 0;
    height: 0;
    border-top: .5rem solid transparent;
    border-right: .5rem solid #1b1c1d;
    border-bottom: .5rem solid transparent;
}

