.Button {
  cursor: pointer;
  outline: none;
  border: none;
  height: 4rem;
  padding: 0 3rem;
  background: #21ba45;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 600;
  border-radius: .25rem;
  margin-right: 2rem;
}

.Green {
  background: #86C232;
}

.Orange {
  background: #222629;
}

.Group {
  padding: 2rem;
  border-radius: 12px;
  background: #f7f7f7;
  border: 1px solid #dedede;
  h3 {
    padding-bottom: 10px;
    border-bottom: 1px solid #dedede;
  }
}