.Group {
  margin-bottom: 1.5rem;
  &:last-child {
    margin-bottom: 0;
  }
}

.GroupFull {
  width: 100% !important;
}

.Label {
  color: #333;
  display: block;
  margin-bottom: .5rem;
  font-size: 1.3rem;
  font-weight: 600;
}



.Error {
  display: block;
  color: red;
  font-size: 1.2rem;
  margin-top: .25rem;
}