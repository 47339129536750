.Pagination {
  position: absolute;
  right: 1.5rem;
  display: flex;
  align-items: center;
}

.Info {
  font-size: 1.3rem;
  margin-right: 1.5rem;
}

.Buttons {
  display: flex;
  margin-left: 1rem;
}

.Button {
  height: 3.2rem;
  min-width: 3.2rem;
  border-radius: 4px;
  border: 1px solid #cfd7df;
  color: #12344d;
  cursor: pointer;
  margin-right: .5rem;
  font-size: 14px;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  transition: .1s ease-in;
  background-image: linear-gradient(to bottom,#fff,#f3f5f7);
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 1.2rem;
  }
}

.Prev {
  img {
    transform: rotate(180deg);
  }
}