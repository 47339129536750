.Wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #252525;
}

.Form {
  width: 50rem;
  padding: 4rem;
  background: #fff;
  border-radius: .5rem;

  &__title {
    margin-bottom: 2rem;
    h2 {
      font-size: 2.4rem;
      margin-bottom: .25rem;
    }
  }

  &__button {
    margin-top: 3rem;
  }
}