@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;800&display=swap');

html {
    font-size: 10px;
    font-family: 'Open Sans', sans-serif;
}

iframe {
    pointer-events: none;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Open Sans', sans-serif;
}

h3 {
    font-size: 1.5rem;
}

tr, td {
    font-size: 1.3rem!important;
}

.select__placeholder {
    font-size: 1.3rem!important;
}

.loading {
    position: fixed;
    bottom: 0;
    right: 0;
    width: calc(100% - 22rem);
    height: calc(100% - 12rem);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
}

.company__info div {
    font-size: 1.5rem;
}

.edit {
    top: -.1rem;
    left: .2rem;
}

.g__logout {
    width: 4.2rem;
    height: 4.2rem;
    display: flex;
    align-content: center;
    justify-content: center;
    position: absolute;
    left: 0!important;
    right: 0!important;
    margin: auto !important;
}

.g__logout i {
    margin-right: 0;
    position: relative;
    left: -.25rem;
}
.g__logout span {
    display: block;
    opacity: 0;
    padding: .5rem 1rem;
    right: -1.5rem;
    position: absolute;
    background: #1b1c1d;
    white-space: nowrap;
    font-size: 1.3rem;
    border-radius: .5rem;
    transform: translateX(100%);
    transition: all .25s ease-in;
}

.g__logout span:after {
    content: "";
    position: absolute;
    left: -.35rem;
    top: 1rem;
    width: 0;
    height: 0;
    border-top: .5rem solid transparent;
    border-right: .5rem solid #1b1c1d;
    border-bottom: .5rem solid transparent;
}
